import React, { useEffect, useMemo, useRef, useState } from "react";
import { base_remote, intro_src, useModelStoreCt } from "./ModelsStore.js";
import { useRace } from "./Race.js";
import { dec, jstr, nils } from "../utils/utils.js";
import transp from "../assets/images/loadingtransparent.png";
import _ from "lodash";
import { Tag } from "./utilityComps.js";
import { twMerge } from "tailwind-merge";
import { useRaceRunContext } from "./RaceRunner.js";
import { Helmet } from "react-helmet-async";
import { InterfaceWrapper, useInterfaceContext } from "./Interface.js";
import { mute_audio, unmute_audio } from "./Audio.js";
import {
  faFastForward,
  faVolumeHigh,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../App.js";

const clearSiteData = async () => {
  // Clear localStorage
  localStorage.clear();

  // Clear cookies
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  // Clear IndexedDB (example for a database named 'myDatabase')
  const req = indexedDB.deleteDatabase("gltfCache");
  req.onsuccess = function () {
    console.log("Deleted database successfully");
  };
  req.onerror = function () {
    console.log("Couldn't delete database");
  };
  req.onblocked = function () {
    console.log("Couldn't delete database due to the operation being blocked");
  };

  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

const Intro = ({ onEnd }) => {
  const incon = useInterfaceContext();
  const { mute, set_mute } = incon;

  const video_src = intro_src.video;
  const audio_src = intro_src.audio;

  const audio_ref = useRef(null);
  const video_ref = useRef(null);

  const mute_audio = () => {
    let a = audio_ref.current;
    if (a) a.muted = true;
  };
  const unmute_audio = () => {
    let a = audio_ref.current;
    if (a) a.muted = false;
  };

  const onEnded = () => {
    if (audio_ref.current) {
      audio_ref.current.pause();
      audio_ref.current.currentTime = 0;
    }
    onEnd();
  };
  const onStart = () => {
    if (audio_ref.current) {
      audio_ref.current.play();
      if (mute) mute_audio();
      else unmute_audio();
    }
  };

  useEffect(() => {
    if (mute) mute_audio();
    else unmute_audio();
  }, [mute]);

  return (
    <>
      <div className="absolute z-[1000] w-full h-[100vh] bg-black top-0 left-0">
        <video
          ref={video_ref}
          autoPlay
          muted={true}
          onEnded={onEnded}
          onPlay={onStart}
          className="absolute h-full w-full object-cover bg-slate-800 z-[8] transition-all duration-1000"
          src={video_src}
        />
        {audio_src && (
          <audio
            ref={audio_ref}
            autoPlay={false}
            className="absolute h-full w-full object-cover bg-slate-800 z-[8] transition-all duration-1000"
            src={audio_src}
          />
        )}
      </div>

      <div className="absolute bottom-[1.2rem] right-[1.2rem] z-[10010] pointer-events-auto">
        <div className="flex flex-row resp-gap-4 resp-p-2">
          <Tag
            className="fr-cc resp-gap-2 resp-text--2"
            onClick={() => {
              set_mute(!mute);
            }}
          >
            <FontAwesomeIcon
              fontSize={10}
              icon={mute ? faVolumeMute : faVolumeHigh}
            />
            <span>{mute ? "Audio Muted" : "Audio ON"}</span>
          </Tag>

          <Tag
            className="fr-cc resp-gap-2 resp-text--2"
            onClick={() => {
              onEnded();
            }}
          >
            <FontAwesomeIcon fontSize={10} icon={faFastForward} />
            <span>{"Skip Intro"}</span>
          </Tag>
        </div>
      </div>
    </>
  );
};

function LoadingScreen() {
  const rct = useRace();
  const rruncon = useRaceRunContext();
  const mct = useModelStoreCt();
  const { psearch } = useAppContext();

  const prog = useMemo(() => {
    let a = rct.loaded == true ? 1 : 0;
    let b = mct.loadprogress_n ?? 0;
    let n = _.mean([a, b]);

    // console.log({ a, b, n });
    return n ?? 0;
  }, [rct.loaded, mct.loadprogress_n]);

  const [start, set_start] = useState(false);
  const [showintro, set_showintro] = useState(false);
  const [showbtn, set_showbtn] = useState(false);
  useEffect(() => {
    console.log("prog", { prog });
    if (prog >= 1 && showbtn == false) {
      // setTimeout(() => {
      set_showbtn(true);
      // }, 1500);
    }
  }, [prog]);

  const skip_intro = useMemo(() => {
    if (psearch?.skip_intro == "true") return true;
    else if (rct.rtstatus == "live") return true;
    return false;
  }, [rct.rtstatus, jstr(psearch)]);
  const on_enter_click = () => {
    if (!showbtn) return;
    set_start(true);

    if (skip_intro) {
      rruncon.set_init(true);
    } else {
      set_showintro(true);
      // rruncon.set_init(true);
    }
  };
  if (showintro)
    return (
      <InterfaceWrapper>
        <Intro
          onEnd={() => {
            set_showintro(false);
            rruncon.set_init(true);
          }}
        />
      </InterfaceWrapper>
    );

  if (start) return <></>;

  return (
    <div className="LoadingScreen absolute z-[1000] w-full h-[100vh] bg-black top-0 left-0">
      <Helmet>
        <title>{"Loading 3D... FBike || DNA Racing"}</title>
      </Helmet>

      <div className="fc-cc mt-[20vh]">
        <div className="relative w-[12rem] h-[12rem] overflow-hidden">
          <div className="absolute w-full h-full bg-slate-800 z-[8] transition-all duration-1000"></div>
          <div
            style={{
              marginTop: `${12 * (1 - prog)}rem`,
            }}
            className="absolute w-full h-full bg-acc0 z-[10] transition-all duration-1000"
          ></div>
          <div className="absolute img-obey-cont z-[20]">
            <img src={transp} alt="" />
          </div>
        </div>
        {!nils(rct?.race_err) ? (
          <>
            <div className="absolute top-[5rem] text-red-400 fc-cc w-full">
              <div className="text-[1.5rem]">Error Occured!!</div>
              <div className="text-[1.2rem]">{rct.race_err}</div>
            </div>
          </>
        ) : (
          <>
            {prog >= 1 && showbtn ? (
              <Tag
                onClick={on_enter_click}
                className={twMerge(
                  "animate-pulse cursor-pointer opacity-100",
                  "my-2 xs:px-4 md:px-4 text-white border border-white font-digi text-[0.8rem]",
                  "transition-all duration-500",
                )}
              >
                Enter 3D Experience
              </Tag>
            ) : prog < 0.8 ? (
              <p className="resp-text--1">Loading... may take a minute</p>
            ) : prog < 0.95 ? (
              <p className="resp-text--1">Hang in there...</p>
            ) : prog >= 0.95 ? (
              <p className="resp-text--1">Almost there...</p>
            ) : null}
          </>
        )}
        <div className="fr-sc w-[50rem] max-w-[95vw] mx-auto  absolute top-[1rem]">
          <Tag
            onClick={clearSiteData}
            className={twMerge(
              "border border-red-300 text-red-300",
              "my-2 xs:px-4 md:px-4 text-[0.6rem]",
            )}
          >
            clear cache
          </Tag>
        </div>
      </div>
    </div>
  );
}
export default LoadingScreen;
